import React from "react"

import { PageHeader } from "../../common/pageHeaders"
import PageText from "../../common/pageText"
import { HomeBttn } from "../../common/Buttons"

import SuccessSvg from "../../svg/completeEmail_v1.svg"
import style from "./success.module.css"

const SuccessPage = () => {
  return (
    <>
      <section>
        <PageHeader>OH YEAH!</PageHeader>
        <div className={style.bodyLayout}>
          <PageText>Your email has been submitted!</PageText>
          <PageText>
            Should expect a response soon but for now, thanks for checking out
            the site and reaching out.
          </PageText>
          <HomeBttn>Back Home</HomeBttn>
        </div>
        <img
          src={SuccessSvg}
          style={{ margin: `0 auto` }}
          alt="success svg the email went"
        />
      </section>
    </>
  )
}

export default SuccessPage
