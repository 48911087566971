import React from 'react'
import style from './text.module.css'

const PageText = ({children}) =>{
    return(
        <>
            <p className={style.text}>
                {children}
            </p>
        </>
    )
}

export default PageText;