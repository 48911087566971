import React from "react"
import "../../../style/app.css"
import style from "./navHeaders.module.css"

export const PageHeader = ({ children }) => {
  return (
    <>
      <h2 className={style.header}>{children}</h2>
    </>
  )
}

export const PageSubHeader = ({ children }) => {
  return <h4 className={style.subHeader}>{children}</h4>
}

export const LandingPageSubHeader = ({ children }) => {
  return <h4 className={style.landingPageSubHeader}>{children} </h4>
}
