import React from "react"
import Success from "../components/Contact/successContactPage"
import Layout from "../components/layout"
import { SUCCESS_CONTACT_SEO } from "../config/siteMetaData"

const SuccessPage = () => {
  return (
    <Layout {...SUCCESS_CONTACT_SEO}>
      <Success />
    </Layout>
  )
}

export default SuccessPage
