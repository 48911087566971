import LANDING_SEO_IMAGE from "../static/placeholder_landing.jpg"
import ABOUT_SEO_IMAGE from "../static/seo_professional_photo.jpg"
import PROJECT_SEO_IMAGE from "../static/fwens.jpg"
import PROJECT_TUFAS_SEO_IMAGE from "../static/projects/seo_new_tufas.png"
import PROJECT_LUXO_SEO_IMAGE from "../static/projects/luxo_seo.jpg"
import CONTACT_SEO_IMAGE from "../static/seo_walk_out.png"

export const LANDING_SEO = {
  title: "Landing",
  description:
    "Introduction of Hector Benitez as a Developer. Useful guides to navigate within the site, e.g. Projects, About pages",
  seoImage: LANDING_SEO_IMAGE,
}

export const ABOUT_SEO = {
  title: "About",
  description:
    "Hector Benitez is a LA native who recently moved from PA back to his home state. Coming up to two years with J&J as a web developer, Hector enjoys time away from his desk by going out to climb and spend time with his fiancé",
  seoImage: ABOUT_SEO_IMAGE,
}

export const PROJECTS_SEO = {
  title: "Projects",
  description: "Most recent projects completed with tech I am interested in.",
  seoImage: PROJECT_SEO_IMAGE,
}

export const PROJECT_PERSONAL_SEO = {
  title: "hecben.dev",
  description:
    "Website developed from start to finish. Using Figma and Zeplin as a design template, host with Netlify and using Gatsby.js as the site-generator. ",
  seoImage: LANDING_SEO_IMAGE,
}

export const PROJECT_TUFAS_SEO = {
  title: "Tufas",
  description:
    "Tufas bouldering lounge contracted me to help with SEO and content layout. Used Wordpress to clear un-used components and to update pages.",
  seoImage: PROJECT_TUFAS_SEO_IMAGE,
}

export const PROJECT_LUXO_SEO = {
  title: "Luxo",
  description:
    "luxo-music.com is a Gatsby.js generated site, serving as a medium for fans to reach out to dj LUXO. I was in charge of maintaining and developing the 'Tutoring and Mentorship' page",
  seoImage: PROJECT_LUXO_SEO_IMAGE,
}

export const CONTACT_SEO = {
  title: "Contact",
  description:
    "Submit an email to connect with me! Whether it be a collaborative effort for a project, to climb or hang out!",
  image: CONTACT_SEO_IMAGE,
}

export const SUCCESS_CONTACT_SEO = {
  title: "Success",
  description: "Message Sent",
}
