import React from "react"
import style from "./button.module.css"
import { Link } from "gatsby"
import { HOME, ABOUT, PROJECTS } from "../../../utils/urls"

export const RegBttn = ({ children }) => {
  return (
    <Link to={PROJECTS} className={style.regularBttn}>
      {children}
    </Link>
  )
}

export const FillerBtn = ({ children }) => {
  return (
    <Link to={ABOUT} className={style.fillerBtn}>
      {children}
    </Link>
  )
}

export const HomeBttn = ({ children }) => {
  return (
    <Link to={HOME} className={style.regularBttn}>
      {children}
    </Link>
  )
}

export const MobileNavBttn = ({ children }) => {
  return <button className={style.mobileNavBttn}>{children}</button>
}
